import {createRouter, createWebHashHistory} from 'vue-router'
import BaseLayout from "@/layouts/BaseLayout";

const routes = [
    {
        path: '/',
        component: BaseLayout,
        redirect: "/home",
        meta: {
            keepAlive: true,
            icon: "el-icon-s-platform",
            title: "控制台",
            permission: ["admin", "examine", "service"]
        },
        children: [
            {
                path: "/home",
                component: () => import("../views/index/index"),
                meta: {title: '数据展示', hidden: false, permission: ["admin", "examine", "service"]},
            }
        ]
    },
    {
        path: '/member',
        component: BaseLayout,
        redirect: "/member/index",
        meta: {
            title: '客户管理',
            icon: "el-icon-s-custom",
            hidden: false,
            permission: ["admin", "examine"]
        },
        children: [
            {
                path: "/member/index",
                component: () => import("../views/member/index"),
                meta: {title: '全部客户', hidden: false, permission: ["admin"]},
            },
            {
                path: "/member/profile_examine",
                component: () => import("../views/member/examine"),
                meta: {
                    title: '资料建档',
                    hidden: false,
                    permission: ["admin", "examine", "service"],
                    active: "/member/index"
                },
            },
            {
                path: "/waitrx/index",
                component: () => import("../views/waitrx/index"),
                meta: {title: '待开处方', hidden: false, permission: ["admin", "examine", "service"]},
            },
            {
                name:"waitrx_detail",
                path: "/waitrx/detail/:id",
                component: () => import("../views/waitrx/detail"),
                meta: {title: '待开详情', hidden: true, permission: ["admin", "examine", "service"],active: '/waitrx/index'},
            },
            {
                path: "/putonrecord/lists",
                component: () => import("../views/member/putonrecord/lists"),
                meta: {title: '用药建档', hidden: false, permission: ["admin", "examine", "service"]},
            },
            {
                name: "member_detail",
                path: "/member/detail/:id",
                component: () => import("../views/member/detail"),
                meta: {
                    title: '客户详情',
                    hidden: true,
                    permission: ["admin", "examine", "service"],
                    active: "/member/index"
                },
            },
            {
                name: "putonrecord_detail",
                path: "/putonrecord/detail/:id",
                component: () => import("../views/member/putonrecord/detail"),
                meta: {title: '用药建档详情', hidden: true, permission: ["admin", "examine", "service"] , active: "/putonrecord/lists"},
            },
            {
                name: "member_edit",
                path: "/member/edit/:id",
                component: () => import("../views/member/edit"),
                meta: {title: '编辑客户资料', hidden: true, permission: ["admin", "examine", "service"]},
            },
        ]
    },
    {
        path: '/thumb',
        component: BaseLayout,
        redirect: "/thumb/batch",
        meta: {
            keepAlive: true,
            hidden: false,
            title: '贴画制作',
            icon: 'el-icon-box',
            permission: ["admin"]
        },
        children: [
            {
                path: "/thumb/batch",
                component: () => import("../views/thumb/index"),
                meta: {title: '批量制作', hidden: false, permission: ["admin", "examine", "service"]},
            },
            {
                path: "/thumb/single",
                component: () => import("../views/thumb/index"),
                meta: {title: '单张制作', hidden: true, permission: ["admin", "examine", "service"]},
            },
            {
                path: "/member/thumbexamine",
                component: () => import("../views/thumb/examine"),
                meta: {title: '画报审核', hidden: true, permission: ["admin", "examine", "service"]},
            },
        ]
    },
    {
        path: '/product',
        component: BaseLayout,
        redirect: "/product/index",
        meta: {
            keepAlive: true,
            hidden: false,
            title: '商品管理',
            icon: 'el-icon-box',
            permission: ["admin"]
        },
        children: [
            {
                path: "/product/index",
                component: () => import("../views/product/index"),
                meta: {title: '全部商品', hidden: false, keepAlive: true, permission: ["admin", "examine", "service"]}
            },
            {
                path: "/product/nograph",
                component: () => import("../views/product/nograph"),
                meta: {title: '无图商品', hidden: false, keepAlive: true, permission: ["admin", "examine", "service"]}
            },
            {
                path: "/product/add",
                component: () => import("../views/product/edit"),
                meta: {title: '新增商品', hidden: true, permission: ["admin", "examine", "service"]}
            },
            {
                name: "product_edit",
                path: "edit/:id",
                component: () => import("../views/product/edit"),
                meta: {title: '编辑商品', hidden: true, keepAlive: true, permission: ["admin", "examine", "service"]}
            },
            {
                path: "/product/demand",
                component: () => import("../views/product/demand"),
                meta: {title: '商品需求', hidden: false, keepAlive: true, permission: ["admin", "examine", "service"]}
            },
        ]
    },
    {
        path: '/order',
        component: BaseLayout,
        redirect: "/order/index",
        meta: {
            title: '订单管理',
            icon: 'el-icon-shopping-bag-1',
            hidden: false,
            permission: ["admin", "examine"]
        },
        children: [
            {
                path: "/order/index",
                component: () => import("../views/order/index"),
                meta: {title: '全部订单', hidden: false, permission: ["admin"]}
            },
            {
                path: "/order/examine",
                component: () => import("../views/order/examine"),
                meta: {title: '订单审核', hidden: false, permission: ["admin", "examine", "service"]}
            },
            {
                path: "/order_turn/index",
                component: () => import("../views/order_turn/index"),
                meta: {title: '缴款确认', hidden: false, permission: ["admin"]}
            },
            {
                path: "/order_deine/index",
                component: () => import("../views/order_deine/index"),
                meta: {title: '退货订单', hidden: false, permission: ["admin"]}
            },
            {
                name: "order_doExamine",
                path: "/order/doExamine/:id",
                component: () => import("../views/order/doExamine"),
                meta: {title: '订单审核', hidden: true, permission: ["admin", "examine", "service"]}
            },
            {
                name: "order_detail",
                path: "detail/:id",
                component: () => import("../views/order/detail"),
                meta: {title: '订单详情', hidden: true, permission: ["admin", "examine", "service"]}
            },
            {
                name: "examine",
                path: "examine",
                component: () => import("../views/product/edit"),
                meta: {title: '订单审核', hidden: true, permission: ["admin", "examine", "service"]}
            }
        ]
    },
    {
        path:'/outbound/print',
        component: () => import("../views/order/print"),
        meta: {
            title: '出库管理',
            icon: 'el-icon-finished',
            hidden: true,
            permission: ["admin", "examine"]
        },
    },
    {
        path: '/outbound',
        component: BaseLayout,
        redirect: "/outbound/auto",
        meta: {
            title: '出库管理',
            icon: 'el-icon-finished',
            hidden: false,
            permission: ["admin", "examine"]
        },
        children: [
            {
                path: "/outbound/auto",
                component: () => import("../views/outbound/index"),
                meta: {title: '智能出库', hidden: false, permission: ["admin"]}
            },
            {
                path: "/outbound/deliver",
                component: () => import("../views/outbound/deliver"),
                meta: {title: '打单发货', hidden: false, permission: ["admin"]}
            },
            {
                path: "/outbound/oncelists",
                component: () => import("../views/outbound/oncelists"),
                meta: {title: '单项出库', hidden: false, permission: ["admin"]}
            },
            {
                path: "/outbound/lists",
                component: () => import("../views/outbound/lists"),
                meta: {title: '出库记录', hidden: false, permission: ["admin"]}
            },
            {
                name:"outbound_plan",
                path: "/outbound/plan/:region/:ids",
                component: () => import("../views/outbound/regionplan"),
                meta: {title: '出库方案', hidden: true, permission: ["admin"]}
            },
        ]
    },
    {
        path: '/placeOrder',
        component: BaseLayout,
        redirect: "/placeOrder/query",
        meta: {
            title: '客户服务',
            icon: 'el-icon-service',
            hidden: false,
            permission: ["admin", "service"]
        },
        children: [
            {
                path: "/placeOrder/query",
                component: () => import("../views/placeOrder/query"),
                meta: {title: '快速下单', hidden: false, permission: ["admin", "examine", "service"]}
            },
            {
                path: "/placeOrder/fastreg",
                name:"fastreg",
                component: () => import("../views/placeOrder/fastreg"),
                meta: {title: '快速注册', hidden: true, permission: ["admin", "examine", "service"]}
            },
            {
                path: "/placeOrder/applyLists",
                component: () => import("../views/placeOrder/applyLists"),
                meta: {title: '销售申请确认', hidden: false, permission: ["admin", "examine", "service"]}
            },
            {
                name: "placeOrder_applyView",
                path: "/placeOrder/applyView/:id",
                component: () => import("../views/placeOrder/applyView"),
                meta: {
                    title: '查看订单',
                    hidden: true,
                    permission: ["admin", "examine", "service"],
                    active: "/placeOrder/applyLists"
                }
            },
            {
                name: "placeOrder_apply",
                path: "/placeOrder/apply/:id",
                component: () => import("../views/placeOrder/apply"),
                meta: {
                    title: '订单详情',
                    hidden: true,
                    permission: ["admin", "examine", "service"],
                    active: "/placeOrder/query"
                }
            },
            {
                name: "examine",
                path: "examine",
                component: () => import("../views/product/edit"),
                meta: {title: '订单审核', hidden: true, permission: ["admin", "examine", "service"]}
            }
        ]
    },
    {
        path: '/region',
        component: BaseLayout,
        redirect: "/region/index",
        meta: {
            title: '区域&人员',
            icon: 'el-icon-s-flag',
            hidden: false,
            permission: ["admin"]
        },
        children: [
            {
                path: "/region/index",
                component: () => import("../views/region/index"),
                meta: {title: '区域管理', hidden: false, permission: ["admin", "examine", "service"]}
            },
            {
                name: "region_add",
                path: "/region/add/:pid",
                component: () => import("../views/region/add"),
                meta: {
                    title: '新增区域',
                    hidden: true,
                    permission: ["admin", "examine", "service"],
                    active: "/region/index"
                }
            },
            {
                name: "region_edit",
                path: "/region/edit/:id",
                component: () => import("../views/region/edit"),
                meta: {
                    title: '编辑区域',
                    hidden: true,
                    permission: ["admin", "examine", "service"],
                    active: "/region/index"
                }
            },
            {
                name: "worker_index",
                path: "/worker/index",
                component: () => import("../views/worker/index"),
                meta: {title: '人员管理', hidden: false, permission: ["admin", "examine", "service"]}
            },
            {
                name: "worker_add",
                path: "/worker/add",
                component: () => import("../views/worker/add"),
                meta: {
                    title: '添加人员',
                    hidden: true,
                    permission: ["admin", "examine", "service"],
                    active: "/worker/index"
                }
            },
            {
                name: "worker_edit",
                path: "/worker/edit/:id",
                component: () => import("../views/worker/add"),
                meta: {
                    title: '编辑人员',
                    hidden: true,
                    permission: ["admin", "examine", "service"],
                    active: "/worker/index"
                }
            }
        ]
    },
    {
        path: '/auth',
        component: BaseLayout,
        redirect: "/auth/group",
        meta: {
            title: '权限管理',
            icon: 'el-icon-lock',
            hidden: false,
            permission: ["admin"]
        },
        children: [
            {
                path: "/auth/group",
                component: () => import("../views/auth/group"),
                meta: {title: '角色管理', hidden: false, permission: ["admin", "examine", "service"]}
            },
            {
                name: "account_index",
                path: "/account/index",
                component: () => import("../views/account/index"),
                meta: {title: '账户管理', hidden: false, permission: ["admin", "examine", "service"]}
            },
            {
                path: "/account/add",
                component: () => import("../views/account/edit"),
                meta: {title: '新增账户', hidden: true, permission: ["admin", "examine", "service"]},
            },
            {
                name: "account_edit",
                path: "/account/edit/:id",
                component: () => import("../views/account/edit"),
                meta: {title: '编辑账户', hidden: true, permission: ["admin", "examine", "service"]},
            }
        ]
    },
    {
        path: '/marketing',
        component: BaseLayout,
        redirect: "/coupon/index",
        meta: {
            title: '营销管理',
            icon: 'el-icon-lock',
            hidden: false,
            permission: ["admin"]
        },
        children: [
            {
                path: "/coupon/index",
                component: () => import("../views/coupon/index"),
                meta: {title: '满减规则', hidden: false, permission: ["admin", "examine", "service"]}
            },
            {
                path: "/coupon/add",
                component: () => import("../views/account/edit"),
                meta: {title: '新增账户', hidden: true, permission: ["admin", "examine", "service"]},
            },
            {
                name: "coupon_edit",
                path: "/account/edit/:id",
                component: () => import("../views/account/edit"),
                meta: {title: '编辑账户', hidden: true, permission: ["admin", "examine", "service"]},
            },
            {
                path: "/lottery/index",
                component: () => import("../views/lotteries/index"),
                meta: {title: '抽奖管理', hidden: false, permission: ["admin", "examine", "service"]}
            },
            {
                path: "/lottery/logs",
                component: () => import("../views/lotteries/logs"),
                meta: {title: '中奖记录', hidden: false, permission: ["admin", "examine", "service"]}
            }
        ]
    },
    {
        path: '/boss',
        component: BaseLayout,
        redirect: "/boss/index",
        meta: {
            title: '小老板管理',
            icon: 'el-icon-lock',
            hidden: false,
            permission: ["admin"]
        },
        children: [
            {
                path: "/boss/index",
                component: () => import("../views/boss/index"),
                meta: {title: '全部小老板', hidden: false, permission: ["admin", "examine", "service"]}
            },
            {
                path: "/boss/introduce",
                component: () => import("../views/boss/introduce"),
                meta: {title: '介绍的客户', hidden: false, permission: ["admin", "examine", "service"]}
            },
            {
                path: "/boss/apply",
                component: () => import("../views/boss/apply"),
                meta: {title: '附加身份申请', hidden: false, permission: ["admin", "examine", "service"]}
            },
            {
                path: "/boss/coupons",
                component: () => import("../views/boss/coupons"),
                meta: {title: '消费券明细', hidden: false, permission: ["admin", "examine", "service"]}
            },
            {
                path: "/boss/authaccess",
                component: () => import("../views/boss/authaccess"),
                meta: {title: '邀请小老板', hidden: false, permission: ["admin", "examine", "service"]}
            },
            {
                path: "/boss/money_logs",
                component: () => import("../views/boss/money_logs"),
                meta: {title: '资金明细', hidden: true, permission: ["admin", "examine", "service"]}
            },
            {
                path: "/boss/withdrawal",
                component: () => import("../views/boss/withdrawal"),
                meta: {title: '提现管理', hidden: false, permission: ["admin", "examine", "service"]}
            },

        ]
    },
    {
        path: '/login',
        component: () => import("../views/user/login"),
        meta: {title: '订单审核', hidden: true}
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export {
    router,
    routes
}
