<template>
    <router-view v-slot="{ Component }">
        <component :is="Component" />
    </router-view>
</template>

<style lang="scss">
#app {
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    color: #606266; // 常规文字
    min-height: 100vh;
    display: flex;
}
body{
    margin: 0;
    padding: 0;
}
a{
    color: #727cf5;
}
ul{
    padding: 0;
}
#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
.tableTool{
    display: flex;
    align-items: center;
    align-self: center;
    .search{
        display: flex;
        align-self: center;
        align-items: center;
        .el-input,.el-select {
            margin-right: 15px;
        }
    }
}
::-webkit-scrollbar
{
    width: 6px;
    height: 8px;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track
{
    border-radius: 10px;
    background-color: #F5F5F5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: #727CF5;
}
.el-dialog__body{
    padding: 20px !important;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
}
.el-dialog__header{
    padding: 20px !important;
}
.el-table__expanded-cell[class*=cell]{
    padding: 10px;
}
.el-upload-list--picture-card .el-upload-list__item{
    width: 100px !important;
    height: 100px !important;
}
.el-upload--picture-card{
    width: 100px !important;
    height: 100px !important;
    line-height: 100px !important;
}
</style>
