import request from "./index"

const login = data => request.post("auth/login",data);
const update_token = data => request.post("auth/refresh",data);
const auth_info = data => request.get("auth/info",data);

const admin_group_lists = data => request.post("admin_group/lists",data);
const admin_group_all = data => request.post("admin_group/all",data);
const admin_auth_lists = data => request.post("admin_auth/lists",data);
const admin_account_update = data => request.post("admin_account/update",data);
const admin_account_detail = data => request.post("admin_account/detail",data);

const action_log = data => request.post("action_log/lists",data);
const action_getlog = data => request.post("action_log/getlog",data);

const area_city = data => request.post("area/city",data);
const region_index = data => request.post("region/index",data);
const region_lists = data => request.post("region/lists",data);
const region_detail = id => request.get("region/detail",{id:id});

const region_update = data => request.post("region/update",data);
const region_add = data => request.post("region/add",data);

const worker_lists = data => request.post("worker/lists",data);
const worker_detail = data => request.post("worker/detail",data);
const worker_update = data => request.post("worker/update",data);
const worker_withoutauth = data => request.post("worker/withoutauth",data);
const worker_own = data => request.post("worker/own",data);

const region_auth_grant = data => request.post("region_auth/grant",data);
const region_auth_cancel = data => request.post("region_auth/cancel",data);

const member_index = data => request.post("member/index",data);
const member_examine = data => request.post("member/examine",data);
const member_detail = data => request.post("member/detail",data);
const member_changeStatus = data => request.post("member/changeStatus",data);
const member_medicines = data => request.post("member/medicines",data);
const member_export = (data,filename) => request.download("member/export",data,filename);


const member_thumbLists = data => request.post("member/thumbLists",data);
const member_downthumb = (data,filename) => request.postdown("member/thumbDownload",data,filename);
const member_thumbDownSuccess = data => request.post("member/thumbDownSuccess",data);
const member_thumbexamine = data => request.post("member/thumbexamine",data);
const member_thumbchange = data => request.post("member/thumbchange",data);
const member_waitrx = data => request.post("member/waitrx",data);
const wait_rx_detail = data => request.post("member/wait_rx_detail",data);
const member_save_rximgs = data => request.post("member/save_rximgs",data);


const file_getsts = data => request.get("file/getsts",data);
const file_saveinfo = data => request.post("file/saveinfo",data);

const product_lists = data => request.post("product/lists",data); // 产品列表

const product_specs = data => request.post("product/specs",data); // 产品列表
const product_detail = data => request.post("product/detail",data); // 产品列表
const product_update = data => request.post("product/update",data); // 产品列表
const specstatuschange = data => request.post("product/specstatuschange",data); // 产品列表

const product_export = (data,filename) => request.download("product/export",data,filename);


const product_company = data => request.post("product/company",data); // 产品列表

const putonrecord_lists = data => request.post("putonrecord/lists",data); // 客户建档资料
const putonrecord_detail = data => request.post("putonrecord/detail",data); // 客户建档资料
const putonrecord_adopt = data => request.post("putonrecord/adopt",data); // 通过审核
const putonrecord_refuse = data => request.post("putonrecord/refuse",data); // 通过审核


const place_an_order = data => request.post("order/place_an_order",data); // 客户建档资料
const order_lists = data => request.post("order/lists",data); // 客户建档资料
const order_applyLists = data => request.post("order/applyLists",data); // 客户建档资料
const confirmOrder = data => request.post("order/confirmOrder",data); // 客户建档资料
const refuseOrder = data => request.post("order/refuseOrder",data); // 客户建档资料
const cancelOrder = data => request.post("order/cancelOrder",data); // 客户建档资料
const examineLists = data => request.post("order/examineLists",data); // 客户建档资料
const order_detail = data => request.post("order/detail",data); // 客户建档资料
const order_adopt = data => request.post("order/adopt",data); // 客户建档资料
const orderlog = data => request.post("orderlog/lists",data); // 客户建档资料

const outbound_index = data => request.post("outbound/index",data); // 客户建档资料
const outbound_plan = data => request.post("outbound/plan",data); // 客户建档资料
const outbound_deliver = data => request.post("outbound/deliver",data); // 客户建档资料
const outbound_lists = data => request.post("outbound/lists",data); // 客户建档资料

const order_turn_lists = data => request.post("order_turn/lists",data);
const order_turn_confirm = data => request.post("order_turn/confirm",data);

const order_deine_lists = data => request.post("order_deine/lists",data);

const coupon_index = data => request.post("coupon/index",data); // 客户建档资料
const coupon_batch = data => request.post("coupon/batch",data); // 客户建档资料

const boss_index = data => request.post("boss/lists",data);
const boss_update = data => request.post("boss/update",data);
const boss_introduce = data => request.post("boss/introduce",data);
const boss_reassign = data => request.post("boss/reassign",data);
const boss_apply = data => request.post("boss/apply",data);
const boss_doapply = data => request.post("boss/doApply",data);
const region_auth_search = data => request.post("region_auth/search",data);

export {
    login,
    update_token,
    auth_info,
    admin_group_lists,
    admin_group_all,
    admin_auth_lists,
    admin_account_update,
    admin_account_detail,
    action_log,
    action_getlog,
    area_city,
    region_update,
    region_add,
    region_index,
    region_lists,
    region_detail,
    worker_lists,
    worker_detail,
    worker_update,
    worker_withoutauth,
    worker_own,
    region_auth_grant,
    region_auth_cancel,
    region_auth_search,
    member_index,
    member_examine,
    member_detail,
    member_changeStatus,
    member_medicines,
    member_export,
    member_thumbLists,
    member_downthumb,
    member_thumbDownSuccess,
    member_thumbexamine,
    member_thumbchange,
    member_waitrx,
    wait_rx_detail,
    member_save_rximgs,
    file_getsts,
    file_saveinfo,
    product_specs,
    product_detail,
    product_update,
    product_lists,
    product_company,
    specstatuschange,
    product_export,
    putonrecord_lists,
    putonrecord_detail,
    putonrecord_adopt,
    putonrecord_refuse,
    place_an_order,
    order_lists,
    order_applyLists,
    confirmOrder,
    refuseOrder,
    cancelOrder,
    examineLists,
    order_detail,
    order_adopt,
    orderlog,
    outbound_index,
    outbound_plan,
    outbound_deliver,
    outbound_lists,
    order_turn_lists,
    order_turn_confirm,
    order_deine_lists,
    coupon_index,
    coupon_batch,
    boss_index,
    boss_update,
    boss_introduce,
    boss_apply,
    boss_doapply,
    boss_reassign
}
